// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$white: #ffffff;
$black: #000000;
$off-white: #edffec;
$blue-dark: #466060;
$blue-darker:rgb(56, 78, 78)60;
$grey: #e5eee4;
$grey-dark: #323031;
$orange: #fc9e4f;
$purple-dark: #a15f81;
$purple-light: #b27092;
$green: #77b01e;

// theme-colors
$primary: $purple-light;
$secondary: $grey;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $orange;
$link-decoration: none;
$link-hover-color: darken($link-color, 50%);
$link-hover-decoration: underline;

// typography
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montagu+Slab:opsz,wght@16..144,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap");
$font-family-primary: "Inter", sans-serif;
$font-family-secondary: "Montagu Slab", serif;
$font-family-special: "Reenie Beanie";

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 3.125; // 50px
$h2-font-size: $font-size-base * 2.5; // 40px
$h3-font-size: $font-size-base * 1.875; // 30px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.375; // 22px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;
$headings-color: $purple-dark;

// contextual
$light: $grey;
$dark: $blue-dark;
$text-light: $white;
$text-dark: $black;

// shadow
$shadow: 0 0px 0px rgba($black, 0);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
