// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-title {
		font-size: 50px;
		font-weight: 300;
	}
	.owl-nav,
	.owl-dots,
	.owl-subtitle,
	.owl-description,
	.owl-btn {
		display: none;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 540px;
	max-height: 1080px;
	.owl-title {
		font-size: 80px;
		font-weight: 300;
		@include media-breakpoint-down(md) {
			font-size: 36px;
		}
	}
	.owl-nav,
	.owl-dots,
	.owl-subtitle,
	.owl-description,
	.owl-btn {
		display: none;
	}
	// @include media-breakpoint-up(sm) {
	// 	height: 100vh;
	// }
}
