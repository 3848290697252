.owl-carousel.slider {
    .owl-item {
        .item {
            > a {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}