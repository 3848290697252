// header
.header {
	z-index: 998;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: $blue-dark;
	transition: 0.5s;
	margin: 1.5rem 2.5rem;
	border-radius: 20px;
	box-shadow: 6px 6px 10px #00000029;
	@include media-breakpoint-down(md) {
		margin: 15px;
	}
	&.sticky, &.sticky-mobile {
		box-shadow: $shadow;
		margin: 0;
		border-radius: 0;
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: flex-end;
				padding: 1rem 2rem;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					justify-content: flex-end;
					padding: 10px;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			@extend .btn;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 0.5rem;
			@include media-breakpoint-down(md) {
				order: 2;
				font-size: 12px;
				background: $off-white;
				color: $blue-dark;
				padding: 0 10px !important;
				min-height: 2rem;
				i {
					font-size: 18px;
					display: block;
					min-width: 1rem;
					transition: 0.35s ease;
				}
			}
			&[aria-expanded="true"] {
				i {
					transform: rotate(360deg);
					transition: 0.35s ease;
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 120px;
		margin: 0;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(md) {
			order: -1;
			margin-right: auto;
			width: 81px;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;
			flex-basis: 100%;
			padding: 3vh 15px;
			.navbar-nav {
				.nav-item {
					border-bottom: 1px solid $blue-darker;

					.nav-link {
						font-family: $font-family-primary;
						color: $white;
						font-size: 18px;
						font-weight: 600;
					}

					&.active {
						.nav-link {
							color: $orange !important;
							font-weight: 600;
						}
					}

					.dropdown-menu {
						position: static !important;
						border: 0 !important;
						box-shadow: none !important;
						margin: 0 !important;
						padding: 0 !important;
						transform: none !important;
						background: none !important;

						&::after,
						&::before {
							display: none;
						}

						.nav-item {
							border-bottom: 0;
							padding-left: 1rem;
						}

						.nav-link {
							font-size: 16px;
							font-weight: 200;
							font-family: $font-family-primary;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				gap: 0.5rem;
				margin-left: auto;
				.nav-item {
					padding: 0;
					margin: 0;
					.nav-link {
						color: $white;
						font-family: $font-family-secondary;
						font-size: 16px;
					}
					&.active,
					&:hover {
						.nav-link {
							color: $orange;
						}
					}
					.dropdown-menu.show {
						background: $blue-dark;
						border: 0;
						border-radius: 0 0 8px 8px;
						padding-bottom: 1rem;
						min-width: 105%;
						.nav-item {
							text-align: center;
							.nav-link {
								color: $off-white;
							}
							&:hover .nav-link {
								color: $orange;
							}
						}
						&::after,
						&::before {
							display: none;
						}
					}
				}
			}
		}

		.navbar-nav {
			> .nav-item {
				&.last {
					> .nav-link {
						@extend .btn;
						background: $orange;
						color: $blue-dark;
						font-size: 14px;
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		position: absolute;
		top: 1rem;
		right: 2rem;
		.nav-item {
			.nav-link {
				font-size: 12px;
				font-weight: 300;
				color: $white;
				margin-left: 0.5rem;
				border: 0 !important;
			}
			&:hover {
				border: 0;
				.nav-link {
					color: $orange;
					border: 0 !important;
				}
			}
		}
		@include media-breakpoint-down(md) {
			right: 1rem;
			top: 0.5rem;
		}
	}

	// book-btn
	.book-button {
		@include media-breakpoint-down(md) {
			order: 3;
			a {
				padding: 5px 10px !important;
				font-size: 12px;
			}
		}
		a {
			margin-left: 1rem;
			@extend .btn;
			background: $off-white;
			color: $blue-dark;
			font-size: 14px;
			@include media-breakpoint-down(md) {
				margin-left: 0.5rem;
			}
		}
	}
}
