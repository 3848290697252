$offset: 80vh 0vh;

@keyframes fade-in-and-roll {
	from {
		transform: scale(0.8) rotate(-90deg);
		opacity: 0.25;
		filter: blur(4px);
	}

	to {
		transform: scale(1) rotate(0);
		opacity: 1;
		filter: blur(0);
	}
}

@keyframes slide-in-left {
	from {
		transform: translateX(-50%);
		opacity: 0.1;
	}

	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slide-in-right {
	from {
		transform: translateX(50%);
		opacity: 0.1;
	}

	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes switch-icon {
	25% {
		margin-left: 5rem;
	}
	50% {
		// margin-left: -5rem;
		opacity: 0;
	}
	100% {
		margin-left: 0;
		font-weight: 600;
		opacity: 1;
	}
}
@keyframes fade-in {
	from {
		opacity: 0.15;
	}
	to {
		opacity: 1;
	}
}

.animate {
	&.fade-in-and-roll {
		animation: fade-in-and-roll linear 0.35s;
		animation-timeline: view($offset);
	}
	&.slide-in-left {
		animation: slide-in-left linear 0.35s;
		animation-timeline: view($offset);
	}
	&.slide-in-right {
		animation: slide-in-right linear 0.35s;
		animation-timeline: view($offset);
	}
	&.switch-icon {
		animation: switch-icon ease 0.45s 1;
	}
	&.fade-in {
		animation: fade-in ease 0.5s 1;
		animation-timeline: view($offset);
	}
}
