// mini-sab
.filters {
	display: flex;
	flex-flow: row nowrap !important;

	@include media-breakpoint-down(md) {
		flex-flow: column !important;
		min-width: 100%;
	}
}
&.home .mini-sab {
	background: transparent;
}
.mini-sab {
	background: $grey;
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 990;
			position: relative;
			background: transparent;
			transform: translateY(-50%);
			@include media-breakpoint-down(md) {
				transform: none;
				margin-top: -6vh;
			}
			// below eyecatcher

			// above eyecatcher
			/* @include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 0;
			} */
		}
	}
}
