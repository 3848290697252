// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
	.description {
		@include media-breakpoint-down(md) {
			font-size: 18px !important;
		}
	}
}

// .lead-section + .content-section,
// .lead-section + .bundle-overview-section {
// 	margin-top: calc(-6vh + 30px);
// }
// home intro
.home-intro-section {
	margin: 16vh auto 3vh auto;
	position: relative;
	z-index: 900;
	.container {
		.column {
			position: relative;
			.resource-image {
				position: absolute;
				left: 0;
				top: 0;
				width: 35%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				@extend .animate, .fade-in-and-roll;
				img {
					border-radius: 50%;
					width: 90%;
					aspect-ratio: 1/1;
				}
				@include media-breakpoint-down(md) {
					top: unset;
					bottom: -22vh;
					left: 1.5rem;
					width: 63%;
				}
			}
			div.title,
			div.description {
				max-width: 65%;
				min-width: 65%;
				margin-left: auto;
				text-align: right;
				@extend .animate, .slide-in-right;
				@include media-breakpoint-down(md) {
					max-width: 80%;
					flex-basis: 80%;
				}
			}

			.description {
				font-size: 30px;
				margin-bottom: 3vh;
				@include media-breakpoint-down(md) {
					font-size: 18px;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		margin: 6vh auto 16vh auto;
	}
}

// accommodation section
.accommodation-section {
	@include media-breakpoint-down(md) {
		overflow: hidden;
	}
	&::after {
		content: "";
		background: url("/images/triangle-bottom.svg"),
			url("/images/triangle-bottom.svg");
		background-position: bottom;
		background-repeat: no-repeat;
		background-position: 32.8% bottom, 67.2% bottom;
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 3vh;
		@include media-breakpoint-down(md) {
			background: url("/images/triangle-bottom.svg");
			background-position: bottom;
			background-repeat: no-repeat;
			background-position: 67.2% bottom;
		}
	}
	position: relative;
	padding-top: 12vh !important;
	padding-bottom: 6vh;
	margin-top: 0vh !important;

	background: $grey;
	div.title,
	div.description {
		max-width: 65%;
		min-width: 65%;
		margin-right: auto;
		text-align: left;
		@extend .animate, .slide-in-left;
		h1 {
			color: $black;
		}
		@include media-breakpoint-down(md) {
			max-width: 100%;
		}
	}

	.description {
		font-size: 30px;
		margin-bottom: 3vh;
		@include media-breakpoint-down(md) {
			margin-bottom: 0;
		}
	}
	.slider-container {
		overflow: hidden;
		margin-top: 9vh;
		@include media-breakpoint-down(md) {
			overflow: visible;
			margin-top: 16vh;
		}
		.owl-stage-outer {
			@include media-breakpoint-down(md) {
				overflow: visible;
			}
		}
		.owl-dots,
		.owl-nav {
			display: none;
		}
		.owl-item {
			.item {
				aspect-ratio: 1/1;
				border-radius: 20px;
			}
			.owl-subtitle,
			.owl-description {
				display: none;
			}
			&:nth-child(even) .item {
				&:hover .owl-caption {
					transform: rotate(-10deg) scale(1.05);
					transition: 0.35s ease;
				}
			}

			&:nth-child(odd) .item {
				&:hover .owl-caption {
					transform: rotate(10deg) scale(1.05) !important;
					transition: 0.35s ease;
				}
			}
		}
		.owl-caption {
			position: absolute;
			right: 1rem;
			top: 1rem;
			background: $white;
			width: 40%;
			aspect-ratio: 1/1;
			border-radius: 50%;
			padding: 2rem;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-flow: column;
			overflow: hidden;
			transition: 0.35s ease;
			.owl-title {
				font-size: 30px;
				font-family: $font-family-primary;
				color: $blue-dark;
				font-weight: 900;
			}
			.owl-description {
				color: $blue-dark;
				font-size: 18px;
				text-align: center;
				overflow: hidden;
				text-overflow: ellipsis;
				max-height: 5ch;
			}
			.owl-btn {
				display: none;
			}
			@include media-breakpoint-down(md) {
				top: 0;
				right: unset;
				left: 50%;
				transform: translate(-50%, -50%);
				.owl-title {
					font-size: 20px !important;
				}
				.owl-description {
					font-size: 14px !important;
				}
			}
		}
	}
}

// facilities section
.facilities-section {
	padding: 6vh 0;
	.title,
	.description,
	.text-link {
		@extend .animate, .slide-in-right;
	}
	.one {
		flex-basis: 55% !important;
		max-width: 55% !important;
		.vocabulary-term-list {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			height: 100%;
			row-gap: 1rem;
			li {
				width: calc(33% - 2rem);
				min-width: 180px;
				min-height: 120px;
				aspect-ratio: 1.5/1;
				max-width: calc(33% - 2rem);
				flex-basis: calc(33% - 2rem);
				display: flex;
				flex-flow: column;
				justify-content: flex-end;
				gap: 0.5rem;
				background: $orange;
				border-radius: 20px;
				padding: 0.5rem 1rem;
				color: $white;
				font-size: 19px;
				font-weight: 900;
				a {
					text-decoration: none;
					color: $white;
				}
				i {
					font-size: 36px;
					max-width: 150px;
					color: $white;
				}
				&:hover {
					transform: translateY(-0.15rem);
					i {
						display: block;
						overflow: hidden;
						font-weight: 600;

						&::before {
							@extend .animate, .switch-icon;
						}
					}
				}
			}
		}
	}
	.two {
		flex-basis: 45% !important;
		max-width: 45% !important;
		.description {
			font-size: 30px;
		}
	}
	@include media-breakpoint-down(md) {
		.one,
		.two {
			flex-basis: 100% !important;
			max-width: 100% !important;
		}
		.one {
			.vocabulary-term-list {
				li {
					min-width: 48%;
					flex-basis: 50% !important;
				}
			}
		}
		.two {
			order: -1;
		}
	}
}
// surroundings
.surroundings-section {
	margin: 6vh auto;
	padding: 6vh 0;
	background: $grey;
	position: relative;
	&::before {
		content: "";
		background: url("/images/triangle-bottom.svg"),
			url("/images/triangle-bottom.svg");
		background-position: bottom;
		background-repeat: no-repeat;
		background-position: 47.5% bottom, 82% bottom;
		position: absolute;
		top: 0;
		width: 100%;
		height: 3vh;
		transform: scaleY(-1);
		@include media-breakpoint-down(md) {
			background: url("/images/triangle-bottom.svg");
			background-position: bottom;
			background-repeat: no-repeat;
			background-position: 95% bottom;
		}
	}
	&::after {
		content: "";
		background: url("/images/triangle-bottom.svg"),
			url("/images/triangle-bottom.svg");
		background-position: bottom;
		background-repeat: no-repeat;
		background-position: 47.5% bottom, 82% bottom;
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 3vh;
		@include media-breakpoint-down(md) {
			background: url("/images/triangle-bottom.svg");
			background-position: bottom;
			background-repeat: no-repeat;
			background-position: 95% bottom;
		}
	}
	.one {
		max-width: 45% !important;
		flex-basis: 45% !important;
		display: flex;
		flex-flow: column;
		justify-content: flex-end;
		.title,
		.description,
		.text-link {
			@extend .animate, .slide-in-left;
		}
		.title {
			color: $black;
		}
		.description {
			font-size: 30px;
			margin-top: 0.5rem;
		}
		.text-link {
			margin-bottom: auto;
		}
		.circle {
			margin-top: auto;
			margin-left: auto;
			background: $white;
			width: 200px;
			aspect-ratio: 1/1;
			border-radius: 50%;
			padding: 1rem;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-flow: column;
			overflow: hidden;
			font-size: 30px;
			text-align: center;
			font-weight: 900;
			font-family: $font-family-primary;
			color: $blue-dark;
		}
	}
	.two {
		max-width: 55% !important;
		flex-basis: 55% !important;
		.item {
			max-width: 100% !important;
			flex-basis: 100% !important;
			@extend .animate, .fade-in;
			.card {
				border-radius: 20px;
				.card-title {
					font-size: 30px;
					font-weight: 700;
					font-family: $font-family-primary;
					text-underline-offset: 0.5rem;
					max-width: 75%;
					@include media-breakpoint-down(md) {
						font-size: 22px;
					}
				}
				.card-subtitle {
					display: none;
				}

				.card-description {
					display: flex;
					overflow: hidden;
					max-width: 64px;
					width: 64px;
					aspect-ratio: 1/1;
					position: absolute;
					top: 1rem;
					right: 1rem;
					border-radius: 50%;
					justify-content: center;
					align-items: center;
					background: $white;
					@include media-breakpoint-down(md) {
						width: 40px;
					}
					p {
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0;
						padding: 0.35rem;

						i {
							color: $blue-dark !important;
							font-family: $font-awesome;
							font-size: 26px;
							font-weight: 200;
							font-style: normal;
							@include media-breakpoint-down(md) {
								font-size: 22px;
							}
						}
					}
				}
				&:hover {
					.card-title {
						color: $white !important;
						text-decoration: underline wavy;
						transition: 0.35s ease;
					}
					.card-description p i {
						font-weight: 600;
					}
				}
			}
			&:nth-child(even) {
				.card-description p i {
					color: $purple-dark !important;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.one,
		.two {
			max-width: 100% !important;
			flex-basis: 100% !important;
		}
		.one {
			.circle {
				width: 140px;
				font-size: 20px;
			}
		}
		.two {
			padding-bottom: 3vh;
			order: -1;
		}
	}
}

// usp section
.usp-section {
	padding-bottom: 6vh;
	.vocabulary-term-list {
		display: flex;
		justify-content: space-between;
		width: 100%;
		li {
			width: 260px;
			max-width: 260px;
			background: $green;
			color: $white;
			aspect-ratio: 1/1;
			text-align: center;
			display: flex;
			flex-flow: column;
			justify-content: flex-end;
			padding-bottom: 2rem;
			border-radius: 20px;
			@extend .animate, .fade-in;
			transition: 0.35s ease;
			&:hover {
				transform: translateY(-0.35rem);
				transition: 0.35s ease;
			}
			.list-item-title {
				font-weight: 700;
				font-size: 30px;
			}
			.list-item-short-descritption {
				text-align: center;
				max-width: 80%;
				margin: 0.15rem auto;
				font-size: 18px;
			}
		}
		@include media-breakpoint-down(md) {
			justify-content: center;
			gap: 0.5rem;
			li {
				display: flex;
				padding: 5px;
				max-width: 48%;
				flex-basis: 48%;
				align-items: center;
				justify-content: center;
				.list-item-title {
					font-size: 20px;
				}
				.list-item-short-description {
					font-size: 12px;
					max-width: 100%;
				}
			}
		}
	}
}

// big slider

section.big-slider {
	overflow: hidden;
	.big-slider {
		overflow: hidden;
		.owl-item {
			min-height: 65vh;
			@include media-breakpoint-down(md) {
				min-height: 32vh;
			}

			border-radius: 20px;
			.item {
				width: 100%;
				border-radius: 20px;
				.owl-caption {
					display: none;
				}
			}
		}
	}
	.owl-dots,
	.owl-nav {
		display: none;
	}
}

// outro
.home-outro-section {
	margin: 6vh auto;
	.container.intro {
		.title,
		.description {
			@extend .animate, .slide-in-left;
		}
		.description {
			font-size: 30px;
			max-width: 60%;
			@include media-breakpoint-down(md) {
				max-width: 100%;
			}
		}
		margin-bottom: 6vh;
	}
	.container.two {
		.title h4 {
			font-size: 24px;
			font-weight: 700;
			font-family: $font-family-primary;
			color: $black;
		}
		.description {
			font-size: 20px;
		}
	}
}

// lead-section
// =========================================================================

&.accommodation-detail:not(:has(.eyecatcher)) {
	.lead-section {
		padding-top: 20vh;
	}
}

.main:has(.scroll-to-content) {
	.lead-section {
		.column {
			padding-bottom: 6vh;
		}
	}
	.properties {
		position: absolute;
		right: 0;
		top: 6vh;
		flex-flow: column;
		row-gap: 1.2rem;
		@include media-breakpoint-down(md) {
			position: relative;
			flex-flow: row wrap !important;
			top: 0rem;
			margin-bottom: 0.5rem;
		}
	}
}
.main:has(.gallery-section) {
	.lead-section {
		&::after {
			display: none !important;
		}
		.column {
			padding-bottom: 6vh;
		}
	}
}
.lead-section {
	padding: 6vh 0 6vh 0;
	background: $grey;
	position: relative;
	&::after {
		content: url("/images/triangle-bottom.svg");
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0.5rem);
	}
	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
		.scroll-to-content {
			position: absolute;
			bottom: 0;
			left: 15px;
		}
	}

	.info {
		// accommodation
		display: flex;
		flex-flow: column;
		max-width: 70%;
		@include media-breakpoint-down(md) {
			max-width: 100%;
		}
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;
			margin: 1rem 0;

			li {
				margin: 0 25px 0 0;
				display: flex;
				align-items: center;
				gap: 0.35rem;
				font-size: 18px;
				color: $blue-dark;
				font-weight: 600;
			}

			.icon i {
				margin-right: 5px;
				background: $white;
				width: 64px;
				aspect-ratio: 1/1;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 36px;
				font-weight: 200;
				@include media-breakpoint-down(md) {
					width: 32px;
					font-size: 16px;
				}
			}
		}
		.title {
			order: -1;

			* {
				color: $black;
			}
		}

		.subtitle * {
			font-family: $font-family-primary;
		}
		.page-block p {
			font-size: 30px;
			@include media-breakpoint-down(md) {
				font-size: 24px;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin-top: 6vh !important;
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	padding: 6vh 0;
	background: $grey;
	position: relative;
	&::after {
		content: url("/images/triangle-bottom.svg");
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0.5rem);
	}
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0 0;
	padding: 6vh 0;
	background: $grey;
	h2 {
		margin-bottom: 30px;
		color: $black;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
