// footer
.footer {
	background: $blue-dark;
	padding: 6vh 0 1rem 0;
	position: relative;
	overflow: hidden;
	&::after {
		content: url("/images/tree.svg");
		position: absolute;
		bottom: -5%;
		right: 10%;
		@include media-breakpoint-down(md) {
			right: 5%;
			width: 40%;
		}
	}
	.footer-intro {
		.footer-text.one {
			color: $white;
			max-width: 70%;
			font-size: 20px;
			@include media-breakpoint-down(md) {
				max-width: 100%;
			}
		}
		h1,
		h2,
		h3,
		h4,
		h5 {
			color: $white;
		}
		h6 {
			font-size: 36px;
			transform: rotate(-5deg) translateY(-1rem);
			font-family: $font-family-special !important;
			color: $white;
		}
		.container.contact {
			margin: 7vh auto;
			.container-holder {
				max-width: 80%;
				@include media-breakpoint-down(md) {
					max-width: 100%;
				}
				.one {
					flex-basis: auto !important;
					width: max-content;
					padding-right: 0 !important;
					color: $white;
					@include media-breakpoint-down(md) {
						display: flex;
						flex-flow: column;
						p:has(.btn) {
							display: flex;
							flex-flow: row wrap;
							width: 100%;
							.btn {
								max-width: max-content;
							}
						}
					}
					.btn {
						height: 36px !important;
						padding: 0.5rem 1rem;
						border-radius: 35px;
						font-size: 14px;
						background: $white;
						color: $blue-dark;
						max-width: max-content;
						margin-right: 1ch;
						&:hover {
							background: rgba($white, 0.8);
						}
					}
					a:not(.btn) {
						font-weight: 600;
						&:hover {
							color: $orange;
						}
					}
				}
				.two {
					flex-basis: auto !important;
					display: flex;
					flex-flow: column;
					align-items: flex-start;
					.vocabulary-term-list {
						display: flex;
						flex-flow: row;
						gap: 1rem;
						li {
							span {
								display: none;
							}
							i {
								color: $white;
								font-size: 36px;
								transition: 0.35s ease;
							}
							&:hover {
								i {
									transform: translateY(-5px);
									transition: 0.35s ease;
								}
							}
						}
					}
				}
			}
		}
	}
	.footer-socket {
		.list {
			display: flex;
			gap: 1rem;
			@include media-breakpoint-down(md) {
				display: flex;
				flex-flow: column;
				gap: 0.35rem;
			}
			a {
				font-weight: 600;
				&:hover {
					color: $orange;
				}
			}
		}
	}
}
